<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-row>
                <v-subheader class="success--text"
                             style="border-bottom: 1px solid var(--v-success-base); width: 100%; height: 40px; padding-top: 6px">
                  Налаштування друку
                </v-subheader>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_one_page"
                            label="2 копії на одну сторінку"
                            class="mt-0"
                            @change="duplicateOnOnePageChange"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_differance_page"
                            label="2 копії на різні сторінки"
                            class="mt-0"
                            @change="duplicateOnDifferancePageChange"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.show_signature"
                            label="Виводити підписи"
                            class="mt-0"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea color="success"
                              label="Додатковий текст"
                              auto-grow
                              rows="2"
                              placeholder="Введіть бажаний текст для відображення у документі"
                              v-model="document_setting.additional_text"
                              hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4-landscape-auto" style="height: auto !important; min-height: 215mm">
      <div class="document-print-form">
        <template v-if="document_setting.duplicate_on_one_page">
          <template v-for="copy in 2">
            <table class="document-table-form table-separator" :key="`copy-${copy}`" v-if="document_data.date_0">
              <caption>
                <div class="d-flex justify-start">
                  <div style="flex: 0 0 50%">
                    <div style="width: 90%">
                      <div class="d-flex">
                        <div style="flex: 0 0 90%" class="bordered-all font-weight-bold">
                          Зведена податкова накладна
                        </div>
                        <div style="flex: 1;" class="bordered-all border-ex-left">
                          {{ document_data.consolidated }}
                        </div>
                      </div>
                      <div class="d-flex">
                        <div style="flex: 0 0 90%" class="bordered-all border-ex-top font-weight-bold">
                          Складена на операції, звільнені від оподаткування
                        </div>
                        <div style="flex: 1;" class="bordered-all border-ex-left border-ex-top">

                        </div>
                      </div>
                      <div class="d-flex">
                        <div style="flex: 0 0 90%" class="bordered-all border-ex-top align-center">
                          <span class="font-weight-bold">Не підлягає наданню отримувачу (покупцю)</span>з причини
                          (зазначається відповідний тип причини)
                        </div>
                        <div style="flex: 1;" class="bordered-all border-ex-left border-ex-top">
                          <div style="border-bottom: 1px solid; height: 18px">
                            {{ document_data.not_issued }}
                          </div>
                          <div class="d-flex" style="height: 26px">
                            <div style="flex: 0 0 50%">
                              {{ document_data.not_issued_type_0 }}
                            </div>
                            <div style="flex: 1; border-left: 1px solid">
                              {{ document_data.not_issued_type_1 }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1;" class="d-flex justify-end">
                    <div style="width: 200px; font-size: 12px; line-height: 15px;">
                      ЗАТВЕРДЖЕНО <br>
                      Наказ Міністерства фінансів України
                      31 грудня 2015 року N 1307
                      (у редакції наказу Міністерства фінансів України
                      від 01 березня 2021 року N 131)
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-start align-center mt-3">
                  <div style="flex: 0 0 50%; font-size: 16px; font-weight: bold; text-align: right; padding-right: 20px">
                    ПОДАТКОВА НАКЛАДНА
                  </div>
                  <div style="flex: 1">
                    <div class="d-flex" style="width: 100%">

                      <div style="display: flex; flex: 0 0 260px;" class="code-wrapper">
                        <div class="code-box">{{ document_data.date_0 }}</div>
                        <div class="code-box">{{ document_data.date_1 }}</div>
                        <div class="code-box">{{ document_data.date_2 }}</div>
                        <div class="code-box">{{ document_data.date_3 }}</div>
                        <div class="code-box">{{ document_data.date_4 }}</div>
                        <div class="code-box">{{ document_data.date_5 }}</div>
                        <div class="code-box">{{ document_data.date_6 }}</div>
                        <div class="code-box">{{ document_data.date_7 }}</div>
                      </div>

                      <div style="display: flex; flex: 1;" class="code-wrapper">
                        <div class="code-box">{{ document_data.number_0 }}</div>
                        <div class="code-box">{{ document_data.number_1 }}</div>
                        <div class="code-box">{{ document_data.number_2 }}</div>
                        <div class="code-box">{{ document_data.number_3 }}</div>
                        <div class="code-box">{{ document_data.number_4 }}</div>
                        <div class="code-box">{{ document_data.number_5 }}</div>
                        <div class="code-box" style="border-right: 1px solid !important;">
                          {{ document_data.number_6 }}
                        </div>
                        <div style="width: 20px; text-align: center"> /</div>
                        <div class="code-box" style="position: relative;">
                          <div style="position: absolute; top: -16px; right: -7px;"><small>1</small></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-start">
                  <div style="flex: 0 0 50%; font-size: 16px; font-weight: bold; text-align: right; padding-right: 20px">
                  </div>
                  <div style="flex: 1">
                    <div class="d-flex" style="width: 100%">
                      <div style="flex: 0 0 223px; text-align: center">
                        (дата складання)
                      </div>
                      <div style="flex: 0 0 37px; text-align: center">
                      </div>
                      <div style="flex: 0 0 200px; text-align: center">
                        (порядковий номер)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div style="flex: 0 0 48%" class="bordered-all pa-1">
                    <div class="font-weight-bold" style="font-size: 12px">
                      Постачальник (продавець)
                    </div>
                    <div style="height: 32px; width: 96%; padding: 2px 4px" class="bordered-all">
                      {{ document_data.organization_name }}
                    </div>
                    <div style="text-align: center">
                      (найменування; прізвище, ім'я, по батькові (за наявності) - для фізичної особи - підприємця)
                    </div>
                    <div class="mt-2 d-flex">
                      <div style="display: flex; flex: 0 0 262px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_9 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_10 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_11 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 102px;" class="code-wrapper">
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                      </div>
                      <div style="display: flex; flex: 0 0 216px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.organization_code_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_9 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 32px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.organization_code_type }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="flex: 0 0 262px; text-align: center">
                        (індивідуальний податковий номер)
                      </div>
                      <div style="flex: 0 0 84px; text-align: center">
                        (номер філії<sup><small>2</small></sup>)
                      </div>
                      <div style="flex: 0 0 217px; padding-left: 20px; text-align: center">
                        (податковий номер платника податку<sup><small>3</small></sup> або серія (за наявності) та номер паспорта<sup><small>4</small></sup>)
                      </div>
                      <div style="flex: 0 0 32px; padding-left: 16px; text-align: center">
                        (код<sup><small>5</small></sup>)
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1"></div>
                  <div style="flex: 0 0 48%" class="bordered-all pa-1">
                    <div class="font-weight-bold" style="font-size: 12px">
                      Отримувач (покупець)
                    </div>
                    <div style="height: 32px; width: 96%; padding: 2px 4px" class="bordered-all">
                      {{ document_data.contractor_name }}
                    </div>
                    <div style="text-align: center">
                      (найменування; прізвище, ім'я, по батькові (за наявності) - для фізичної особи - підприємця)
                    </div>
                    <div class="mt-2 d-flex">
                      <div style="display: flex; flex: 0 0 262px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_9 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_10 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_11 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 102px;" class="code-wrapper">
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                      </div>
                      <div style="display: flex; flex: 0 0 216px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.contractor_code_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_9 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 32px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.contractor_code_type }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="flex: 0 0 262px; text-align: center">
                        (індивідуальний податковий номер)
                      </div>
                      <div style="flex: 0 0 84px; text-align: center">
                        (номер філії<sup><small>2</small></sup>)
                      </div>
                      <div style="flex: 0 0 217px; padding-left: 20px; text-align: center">
                        (податковий номер платника податку<sup><small>3</small></sup> або серія (за наявності) та номер паспорта<sup><small>3</small></sup>)
                      </div>
                      <div style="flex: 0 0 32px; padding-left: 16px; text-align: center">
                        (код<sup><small>5</small></sup>)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" style="font-size: 11px; font-weight: bold; line-height: 14px;">
                  <div class="pre-table-header bordered-all">
                    Розділ А
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      І
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Загальна сума коштів, що підлягають сплаті, з урахуванням податку на додану вартість
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_total | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      ІI
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Загальна сума податку на додану вартість, у тому числі:
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_total_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      ІII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      загальна сума податку на додану вартість за основною ставкою
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_20_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      ІV
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      загальна сума податку на додану вартість за ставкою 7 %
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_7_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      V
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      загальна сума податку на додану вартість за ставкою 14 %
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_14_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      VI
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання за основною ставкою (код ставки 20)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_20_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      VII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання за ставкою 7 % (код ставки 7)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_7_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      VIII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання за ставкою 14 % (код ставки 14)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_14_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      IX
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання при експорті товарів за ставкою 0 % (код ставки 901)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_0_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      X
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання на митній території України за ставкою 0 % (код ставки 902)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_0_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      XI
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги операцій, звільнених від оподаткування (код ставки 903)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_0_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      XII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Дані щодо зворотної (заставної) тари
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">

                    </div>
                  </div>
                  <div class="pre-table-header bordered-all border-ex-top border-ex-bottom">
                    Розділ Б
                  </div>
                </div>
              </caption>
              <tr>
                <th rowspan="2" style="width: 40px; max-width: 40px;">
                  № з/п
                </th>
                <th rowspan="2" style="width: 200px; max-width: 200px;">
                  Опис (номенклатура) товарів/послуг продавця
                </th>
                <th colspan="3">
                  Код
                </th>
                <th colspan="2">
                  Одиниця виміру товару/послуги
                </th>
                <th rowspan="2" style="width: 70px; max-width: 70px;">
                  Кількість (об'єм, обсяг)
                </th>
                <th rowspan="2" style="width: 80px; max-width: 80px;">
                  Ціна постачання одиниці товару/послуги або
                  максимальна роздрібна ціна товарів без
                  урахування податку на додану вартість
                </th>
                <th rowspan="2" style="width: 42px; max-width: 42px;">
                  Код ставки
                </th>
                <th rowspan="2" style="width: 42px; max-width: 42px;">
                  Код пільги 7
                </th>
                <th rowspan="2" style="width: 80px; max-width: 80px;">
                  Обсяги постачання (база оподаткування)
                  без урахування податку на додану вартість
                </th>
                <th rowspan="2" style="width: 78px; max-width: 78px;">
                  Сума податку на додану вартість
                </th>
                <th rowspan="2" style="width: 50px; max-width: 50px;">
                  Код виду діяльності сільськогос-подарського товаро-виробника
                </th>
              </tr>
              <tr>
                <th style="width: 84px; max-width: 84px;">товару згідно з УКТ ЗЕД</th>
                <th style="width: 80px; max-width: 80px;">ознаки імпортованого товару 6</th>
                <th style="width: 60px; max-width: 60px;">послуги згідно з ДКПП</th>
                <th style="width: 80px; max-width: 110px;">умовне позначення (українське)</th>
                <th style="width: 40px; max-width: 40px;">код</th>
              </tr>
              <tr class="tr-body" v-for="item in document_data.table" :key="item.table_number">
                <td>{{ item.table_number }}</td>
                <td>{{ item.nomenclature }}</td>
                <td class="text-center">{{ item.code_uktz }}</td>
                <td></td>
                <td class="text-center">{{ item.code_dkpp }}</td>
                <td class="text-center">{{ item.unit_type }}</td>
                <td class="text-center">{{ item.unit_type_code }}</td>
                <td class="text-right">{{ item.count | formatNumber('0.0000') }}</td>
                <td class="text-right">{{ item.price }}</td>
                <td class="text-right">{{ item.percent }}</td>
                <td></td>
                <td class="text-right">{{ item.sum }}</td>
                <td class="text-right">{{ item.tax | formatNumber('0.0000') }}</td>
                <td></td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" style="text-align: center" class="pt-2">
                  Суми податку на додану вартість, нараховані (сплачені) у зв'язку з постачанням товарів/послуг, зазначених
                  у цій накладній, визначені правильно, відповідають сумі податкових зобов'язань продавця.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="1" class="pt-2">

                </td>

                <td colspan="3" class="pt-2" style="vertical-align: bottom;">
                  Посадова (уповноважена) особа / фізична особа
                </td>
                <td colspan="5" style="border-bottom: 1px solid; vertical-align: bottom;">
                  {{ document_data.signed_person_name }}
                </td>
                <td colspan="5" class="pt-2">
                  <div style="display: flex; justify-content: center" class="code-wrapper">
                    <div class="code-box">{{ document_data.signed_person_ipn_0 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_1 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_2 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_3 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_4 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_5 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_6 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_7 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_8 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_9 }}</div>
                  </div>
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="1">

                </td>
                <td colspan="3" style="vertical-align: top;">
                  (законний представник)
                </td>
                <td colspan="5" style="text-align: center">
                  (Власне ім'я ПРІЗВИЩЕ)
                </td>
                <td colspan="5" style="text-align: center">
                  (реєстраційний номер облікової картки платника податків
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-1">
                  1 У порядковому номері після символа "/" зазначається код 2 - у разі здійснення операцій з постачання
                  власновироблених товарів, отриманих за результатами видів діяльності, визначених в пункті 161.3 статті 161
                  Закону України "Про державну підтримку сільського господарства України", або код 5 - у разі складання
                  податкової накладної оператором інвестору за багатосторонньою угодою про розподіл продукції, або код 6 - у
                  разі складання податкової накладної для операцій з постачання товарів, базою оподаткування для яких
                  встановлено максимальні роздрібні ціни.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  2 Зазначається у разі постачання/придбання товарів/послуг філією (структурним підрозділом), яка (який)
                  фактично є від імені головного підприємства - платника податку стороною договору.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  3 Зазначається податковий номер платника податку відповідно до наказу Міністерства фінансів України від 09
                  грудня 2011 року N 1588 "Про затвердження Порядку обліку платників податків і зборів", зареєстрованого у
                  Міністерстві юстиції України 29 грудня 2011 року за N 1562/20300 (із змінами).
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  4 Серію (за наявності) та номер паспорта зазначають у разі, якщо покупець або продавець - фізична особа,
                  яка через свої релігійні переконання відмовляється від прийняття реєстраційного номера облікової картки
                  платника податків та повідомила про це відповідний контролюючий орган і має відмітку у паспорті.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  5 Зазначається код ознаки джерела податкового номера відповідно до реєстру, якому належить податковий
                  номер особи: 1 - Єдиний державний реєстр підприємств та організацій України (ЄДРПОУ); 2 - Державний реєстр
                  фізичних осіб – платників податків (ДРФО); 3 - реєстраційний (обліковий) номер платника податків, який
                  присвоюється контролюючими органами (для платників податків, які не включені до ЄДРПОУ); 4 - серія (за
                  наявності) та номер паспорта (для фізичних осіб, які через свої релігійні переконання відмовляються від
                  прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це
                  відповідний контролюючий орган і мають відмітку у паспорті).
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  6 У випадку постачання товару, ввезеного на митну територію України, у графі 3.2 проставляється позначка
                  "Х".
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  7 (у разі відсутності пільги у довідниках податкових пільг у графі 9 проставляється умовний код
                  "99999999", а у цій графі зазначаються відповідні пункти (підпункти), статті, підрозділи, розділи
                  Податкового кодексу України та/або міжнародного договору, якими передбачено звільнення від оподаткування)
                </td>
              </tr>
            </table>
          </template>
        </template>
        <template v-if="document_setting.duplicate_on_differance_page">
          <template v-for="copy in 2">
            <table class="document-table-form" :key="`copy-${copy}`" v-if="document_data.date_0">
              <caption>
                <div class="d-flex justify-start">
                  <div style="flex: 0 0 50%">
                    <div style="width: 90%">
                      <div class="d-flex">
                        <div style="flex: 0 0 90%" class="bordered-all font-weight-bold">
                          Зведена податкова накладна
                        </div>
                        <div style="flex: 1;" class="bordered-all border-ex-left">
                          {{ document_data.consolidated }}
                        </div>
                      </div>
                      <div class="d-flex">
                        <div style="flex: 0 0 90%" class="bordered-all border-ex-top font-weight-bold">
                          Складена на операції, звільнені від оподаткування
                        </div>
                        <div style="flex: 1;" class="bordered-all border-ex-left border-ex-top">

                        </div>
                      </div>
                      <div class="d-flex">
                        <div style="flex: 0 0 90%" class="bordered-all border-ex-top align-center">
                          <span class="font-weight-bold">Не підлягає наданню отримувачу (покупцю)</span>з причини
                          (зазначається відповідний тип причини)
                        </div>
                        <div style="flex: 1;" class="bordered-all border-ex-left border-ex-top">
                          <div style="border-bottom: 1px solid; height: 18px">
                            {{ document_data.not_issued }}
                          </div>
                          <div class="d-flex" style="height: 26px">
                            <div style="flex: 0 0 50%">
                              {{ document_data.not_issued_type_0 }}
                            </div>
                            <div style="flex: 1; border-left: 1px solid">
                              {{ document_data.not_issued_type_1 }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1;" class="d-flex justify-end">
                    <div style="width: 200px; font-size: 12px; line-height: 15px;">
                      ЗАТВЕРДЖЕНО <br>
                      Наказ Міністерства фінансів України
                      31 грудня 2015 року N 1307
                      (у редакції наказу Міністерства фінансів України
                      від 01 березня 2021 року N 131)
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-start align-center mt-3">
                  <div style="flex: 0 0 50%; font-size: 16px; font-weight: bold; text-align: right; padding-right: 20px">
                    ПОДАТКОВА НАКЛАДНА
                  </div>
                  <div style="flex: 1">
                    <div class="d-flex" style="width: 100%">

                      <div style="display: flex; flex: 0 0 260px;" class="code-wrapper">
                        <div class="code-box">{{ document_data.date_0 }}</div>
                        <div class="code-box">{{ document_data.date_1 }}</div>
                        <div class="code-box">{{ document_data.date_2 }}</div>
                        <div class="code-box">{{ document_data.date_3 }}</div>
                        <div class="code-box">{{ document_data.date_4 }}</div>
                        <div class="code-box">{{ document_data.date_5 }}</div>
                        <div class="code-box">{{ document_data.date_6 }}</div>
                        <div class="code-box">{{ document_data.date_7 }}</div>
                      </div>

                      <div style="display: flex; flex: 1;" class="code-wrapper">
                        <div class="code-box">{{ document_data.number_0 }}</div>
                        <div class="code-box">{{ document_data.number_1 }}</div>
                        <div class="code-box">{{ document_data.number_2 }}</div>
                        <div class="code-box">{{ document_data.number_3 }}</div>
                        <div class="code-box">{{ document_data.number_4 }}</div>
                        <div class="code-box">{{ document_data.number_5 }}</div>
                        <div class="code-box" style="border-right: 1px solid !important;">
                          {{ document_data.number_6 }}
                        </div>
                        <div style="width: 20px; text-align: center"> /</div>
                        <div class="code-box" style="position: relative;">
                          <div style="position: absolute; top: -16px; right: -7px;"><small>1</small></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-start">
                  <div style="flex: 0 0 50%; font-size: 16px; font-weight: bold; text-align: right; padding-right: 20px">
                  </div>
                  <div style="flex: 1">
                    <div class="d-flex" style="width: 100%">
                      <div style="flex: 0 0 223px; text-align: center">
                        (дата складання)
                      </div>
                      <div style="flex: 0 0 37px; text-align: center">
                      </div>
                      <div style="flex: 0 0 200px; text-align: center">
                        (порядковий номер)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div style="flex: 0 0 48%" class="bordered-all pa-1">
                    <div class="font-weight-bold" style="font-size: 12px">
                      Постачальник (продавець)
                    </div>
                    <div style="height: 32px; width: 96%; padding: 2px 4px" class="bordered-all">
                      {{ document_data.organization_name }}
                    </div>
                    <div style="text-align: center">
                      (найменування; прізвище, ім'я, по батькові (за наявності) - для фізичної особи - підприємця)
                    </div>
                    <div class="mt-2 d-flex">
                      <div style="display: flex; flex: 0 0 262px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_9 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_10 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_pdv_ipn_11 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 102px;" class="code-wrapper">
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                      </div>
                      <div style="display: flex; flex: 0 0 216px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.organization_code_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.organization_code_9 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 32px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.organization_code_type }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="flex: 0 0 262px; text-align: center">
                        (індивідуальний податковий номер)
                      </div>
                      <div style="flex: 0 0 84px; text-align: center">
                        (номер філії<sup><small>2</small></sup>)
                      </div>
                      <div style="flex: 0 0 217px; padding-left: 20px; text-align: center">
                        (податковий номер платника податку<sup><small>3</small></sup> або серія (за наявності) та номер паспорта<sup><small>4</small></sup>)
                      </div>
                      <div style="flex: 0 0 32px; padding-left: 16px; text-align: center">
                        (код<sup><small>5</small></sup>)
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1"></div>
                  <div style="flex: 0 0 48%" class="bordered-all pa-1">
                    <div class="font-weight-bold" style="font-size: 12px">
                      Отримувач (покупець)
                    </div>
                    <div style="height: 32px; width: 96%; padding: 2px 4px" class="bordered-all">
                      {{ document_data.contractor_name }}
                    </div>
                    <div style="text-align: center">
                      (найменування; прізвище, ім'я, по батькові (за наявності) - для фізичної особи - підприємця)
                    </div>
                    <div class="mt-2 d-flex">
                      <div style="display: flex; flex: 0 0 262px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_9 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_10 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_pdv_ipn_11 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 102px;" class="code-wrapper">
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                        <div class="code-box small"></div>
                      </div>
                      <div style="display: flex; flex: 0 0 216px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.contractor_code_0 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_1 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_2 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_3 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_4 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_5 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_6 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_7 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_8 }}
                        </div>
                        <div class="code-box small">
                          {{ document_data.contractor_code_9 }}
                        </div>
                      </div>
                      <div style="display: flex; flex: 0 0 32px;" class="code-wrapper">
                        <div class="code-box small">
                          {{ document_data.contractor_code_type }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="flex: 0 0 262px; text-align: center">
                        (індивідуальний податковий номер)
                      </div>
                      <div style="flex: 0 0 84px; text-align: center">
                        (номер філії<sup><small>2</small></sup>)
                      </div>
                      <div style="flex: 0 0 217px; padding-left: 20px; text-align: center">
                        (податковий номер платника податку<sup><small>3</small></sup> або серія (за наявності) та номер паспорта<sup><small>3</small></sup>)
                      </div>
                      <div style="flex: 0 0 32px; padding-left: 16px; text-align: center">
                        (код<sup><small>5</small></sup>)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" style="font-size: 11px; font-weight: bold; line-height: 14px;">
                  <div class="pre-table-header bordered-all">
                    Розділ А
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      І
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Загальна сума коштів, що підлягають сплаті, з урахуванням податку на додану вартість
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_total | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      ІI
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Загальна сума податку на додану вартість, у тому числі:
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_total_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      ІII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      загальна сума податку на додану вартість за основною ставкою
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_20_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      ІV
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      загальна сума податку на додану вартість за ставкою 7 %
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_7_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      V
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      загальна сума податку на додану вартість за ставкою 14 %
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_14_tax | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      VI
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання за основною ставкою (код ставки 20)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_20_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      VII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання за ставкою 7 % (код ставки 7)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_7_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      VIII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання за ставкою 14 % (код ставки 14)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_14_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      IX
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання при експорті товарів за ставкою 0 % (код ставки 901)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_0_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      X
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги постачання на митній території України за ставкою 0 % (код ставки 902)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_0_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      XI
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Усього обсяги операцій, звільнених від оподаткування (код ставки 903)
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">
                      {{ document_data.doc_0_base | formatNumber }}
                    </div>
                  </div>
                  <div class="d-flex pre-table-header bordered-all border-ex-top">
                    <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                      XII
                    </div>
                    <div style="flex: 1; border-right: 1px solid">
                      Дані щодо зворотної (заставної) тари
                    </div>
                    <div style="flex: 0 0 260px; text-align: right">

                    </div>
                  </div>
                  <div class="pre-table-header bordered-all border-ex-top border-ex-bottom">
                    Розділ Б
                  </div>
                </div>
              </caption>
              <tr>
                <th rowspan="2" style="width: 40px; max-width: 40px;">
                  № з/п
                </th>
                <th rowspan="2" style="width: 200px; max-width: 200px;">
                  Опис (номенклатура) товарів/послуг продавця
                </th>
                <th colspan="3">
                  Код
                </th>
                <th colspan="2">
                  Одиниця виміру товару/послуги
                </th>
                <th rowspan="2" style="width: 70px; max-width: 70px;">
                  Кількість (об'єм, обсяг)
                </th>
                <th rowspan="2" style="width: 80px; max-width: 80px;">
                  Ціна постачання одиниці товару/послуги або
                  максимальна роздрібна ціна товарів без
                  урахування податку на додану вартість
                </th>
                <th rowspan="2" style="width: 42px; max-width: 42px;">
                  Код ставки
                </th>
                <th rowspan="2" style="width: 42px; max-width: 42px;">
                  Код пільги 7
                </th>
                <th rowspan="2" style="width: 80px; max-width: 80px;">
                  Обсяги постачання (база оподаткування)
                  без урахування податку на додану вартість
                </th>
                <th rowspan="2" style="width: 78px; max-width: 78px;">
                  Сума податку на додану вартість
                </th>
                <th rowspan="2" style="width: 50px; max-width: 50px;">
                  Код виду діяльності сільськогос-подарського товаро-виробника
                </th>
              </tr>
              <tr>
                <th style="width: 84px; max-width: 84px;">товару згідно з УКТ ЗЕД</th>
                <th style="width: 80px; max-width: 80px;">ознаки імпортованого товару 6</th>
                <th style="width: 60px; max-width: 60px;">послуги згідно з ДКПП</th>
                <th style="width: 80px; max-width: 110px;">умовне позначення (українське)</th>
                <th style="width: 40px; max-width: 40px;">код</th>
              </tr>
              <tr class="tr-body" v-for="item in document_data.table" :key="item.table_number">
                <td>{{ item.table_number }}</td>
                <td>{{ item.nomenclature }}</td>
                <td class="text-center">{{ item.code_uktz }}</td>
                <td></td>
                <td class="text-center">{{ item.code_dkpp }}</td>
                <td class="text-center">{{ item.unit_type }}</td>
                <td class="text-center">{{ item.unit_type_code }}</td>
                <td class="text-right">{{ item.count | formatNumber('0.0000') }}</td>
                <td class="text-right">{{ item.price }}</td>
                <td class="text-right">{{ item.percent }}</td>
                <td></td>
                <td class="text-right">{{ item.sum }}</td>
                <td class="text-right">{{ item.tax | formatNumber('0.0000') }}</td>
                <td></td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" style="text-align: center" class="pt-2">
                  Суми податку на додану вартість, нараховані (сплачені) у зв'язку з постачанням товарів/послуг, зазначених
                  у цій накладній, визначені правильно, відповідають сумі податкових зобов'язань продавця.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="1" class="pt-2">

                </td>

                <td colspan="3" class="pt-2" style="vertical-align: bottom;">
                  Посадова (уповноважена) особа / фізична особа
                </td>
                <td colspan="5" style="border-bottom: 1px solid; vertical-align: bottom;">
                  {{ document_data.signed_person_name }}
                </td>
                <td colspan="5" class="pt-2">
                  <div style="display: flex; justify-content: center" class="code-wrapper">
                    <div class="code-box">{{ document_data.signed_person_ipn_0 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_1 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_2 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_3 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_4 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_5 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_6 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_7 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_8 }}</div>
                    <div class="code-box">{{ document_data.signed_person_ipn_9 }}</div>
                  </div>
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="1">

                </td>
                <td colspan="3" style="vertical-align: top;">
                  (законний представник)
                </td>
                <td colspan="5" style="text-align: center">
                  (Власне ім'я ПРІЗВИЩЕ)
                </td>
                <td colspan="5" style="text-align: center">
                  (реєстраційний номер облікової картки платника податків
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-1">
                  1 У порядковому номері після символа "/" зазначається код 2 - у разі здійснення операцій з постачання
                  власновироблених товарів, отриманих за результатами видів діяльності, визначених в пункті 161.3 статті 161
                  Закону України "Про державну підтримку сільського господарства України", або код 5 - у разі складання
                  податкової накладної оператором інвестору за багатосторонньою угодою про розподіл продукції, або код 6 - у
                  разі складання податкової накладної для операцій з постачання товарів, базою оподаткування для яких
                  встановлено максимальні роздрібні ціни.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  2 Зазначається у разі постачання/придбання товарів/послуг філією (структурним підрозділом), яка (який)
                  фактично є від імені головного підприємства - платника податку стороною договору.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  3 Зазначається податковий номер платника податку відповідно до наказу Міністерства фінансів України від 09
                  грудня 2011 року N 1588 "Про затвердження Порядку обліку платників податків і зборів", зареєстрованого у
                  Міністерстві юстиції України 29 грудня 2011 року за N 1562/20300 (із змінами).
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  4 Серію (за наявності) та номер паспорта зазначають у разі, якщо покупець або продавець - фізична особа,
                  яка через свої релігійні переконання відмовляється від прийняття реєстраційного номера облікової картки
                  платника податків та повідомила про це відповідний контролюючий орган і має відмітку у паспорті.
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  5 Зазначається код ознаки джерела податкового номера відповідно до реєстру, якому належить податковий
                  номер особи: 1 - Єдиний державний реєстр підприємств та організацій України (ЄДРПОУ); 2 - Державний реєстр
                  фізичних осіб – платників податків (ДРФО); 3 - реєстраційний (обліковий) номер платника податків, який
                  присвоюється контролюючими органами (для платників податків, які не включені до ЄДРПОУ); 4 - серія (за
                  наявності) та номер паспорта (для фізичних осіб, які через свої релігійні переконання відмовляються від
                  прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це
                  відповідний контролюючий орган і мають відмітку у паспорті).
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  6 У випадку постачання товару, ввезеного на митну територію України, у графі 3.2 проставляється позначка
                  "Х".
                </td>
              </tr>
              <tr class="tfoot">
                <td colspan="14" class="pt-0">
                  7 (у разі відсутності пільги у довідниках податкових пільг у графі 9 проставляється умовний код
                  "99999999", а у цій графі зазначаються відповідні пункти (підпункти), статті, підрозділи, розділи
                  Податкового кодексу України та/або міжнародного договору, якими передбачено звільнення від оподаткування)
                </td>
              </tr>
            </table>
            <template v-if="copy === 1">
              <p class="document-separator" :key="`sep-${copy}`">&nbsp;</p>
            </template>
          </template>
        </template>
        <template v-if="!document_setting.duplicate_on_differance_page && !document_setting.duplicate_on_one_page">
          <table class="document-table-form" v-if="document_data.date_0">
            <caption>
              <div class="d-flex justify-start">
                <div style="flex: 0 0 50%">
                  <div style="width: 90%">
                    <div class="d-flex">
                      <div style="flex: 0 0 90%" class="bordered-all font-weight-bold">
                        Зведена податкова накладна
                      </div>
                      <div style="flex: 1;" class="bordered-all border-ex-left">
                        {{ document_data.consolidated }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="flex: 0 0 90%" class="bordered-all border-ex-top font-weight-bold">
                        Складена на операції, звільнені від оподаткування
                      </div>
                      <div style="flex: 1;" class="bordered-all border-ex-left border-ex-top">

                      </div>
                    </div>
                    <div class="d-flex">
                      <div style="flex: 0 0 90%" class="bordered-all border-ex-top align-center">
                        <span class="font-weight-bold">Не підлягає наданню отримувачу (покупцю)</span>з причини
                        (зазначається відповідний тип причини)
                      </div>
                      <div style="flex: 1;" class="bordered-all border-ex-left border-ex-top">
                        <div style="border-bottom: 1px solid; height: 18px">
                          {{ document_data.not_issued }}
                        </div>
                        <div class="d-flex" style="height: 26px">
                          <div style="flex: 0 0 50%">
                            {{ document_data.not_issued_type_0 }}
                          </div>
                          <div style="flex: 1; border-left: 1px solid">
                            {{ document_data.not_issued_type_1 }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="flex: 1;" class="d-flex justify-end">
                  <div style="width: 200px; font-size: 12px; line-height: 15px;">
                    ЗАТВЕРДЖЕНО <br>
                    Наказ Міністерства фінансів України
                    31 грудня 2015 року N 1307
                    (у редакції наказу Міністерства фінансів України
                    від 01 березня 2021 року N 131)
                  </div>
                </div>
              </div>
              <div class="d-flex justify-start align-center mt-3">
                <div style="flex: 0 0 50%; font-size: 16px; font-weight: bold; text-align: right; padding-right: 20px">
                  ПОДАТКОВА НАКЛАДНА
                </div>
                <div style="flex: 1">
                  <div class="d-flex" style="width: 100%">

                    <div style="display: flex; flex: 0 0 260px;" class="code-wrapper">
                      <div class="code-box">{{ document_data.date_0 }}</div>
                      <div class="code-box">{{ document_data.date_1 }}</div>
                      <div class="code-box">{{ document_data.date_2 }}</div>
                      <div class="code-box">{{ document_data.date_3 }}</div>
                      <div class="code-box">{{ document_data.date_4 }}</div>
                      <div class="code-box">{{ document_data.date_5 }}</div>
                      <div class="code-box">{{ document_data.date_6 }}</div>
                      <div class="code-box">{{ document_data.date_7 }}</div>
                    </div>

                    <div style="display: flex; flex: 1;" class="code-wrapper">
                      <div class="code-box">{{ document_data.number_0 }}</div>
                      <div class="code-box">{{ document_data.number_1 }}</div>
                      <div class="code-box">{{ document_data.number_2 }}</div>
                      <div class="code-box">{{ document_data.number_3 }}</div>
                      <div class="code-box">{{ document_data.number_4 }}</div>
                      <div class="code-box">{{ document_data.number_5 }}</div>
                      <div class="code-box" style="border-right: 1px solid !important;">
                        {{ document_data.number_6 }}
                      </div>
                      <div style="width: 20px; text-align: center"> /</div>
                      <div class="code-box" style="position: relative;">
                        <div style="position: absolute; top: -16px; right: -7px;"><small>1</small></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-start">
                <div style="flex: 0 0 50%; font-size: 16px; font-weight: bold; text-align: right; padding-right: 20px">
                </div>
                <div style="flex: 1">
                  <div class="d-flex" style="width: 100%">
                    <div style="flex: 0 0 223px; text-align: center">
                      (дата складання)
                    </div>
                    <div style="flex: 0 0 37px; text-align: center">
                    </div>
                    <div style="flex: 0 0 200px; text-align: center">
                      (порядковий номер)
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div style="flex: 0 0 48%" class="bordered-all pa-1">
                  <div class="font-weight-bold" style="font-size: 12px">
                    Постачальник (продавець)
                  </div>
                  <div style="height: 32px; width: 96%; padding: 2px 4px" class="bordered-all">
                    {{ document_data.organization_name }}
                  </div>
                  <div style="text-align: center">
                    (найменування; прізвище, ім'я, по батькові (за наявності) - для фізичної особи - підприємця)
                  </div>
                  <div class="mt-2 d-flex">
                    <div style="display: flex; flex: 0 0 262px;" class="code-wrapper">
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_0 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_1 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_2 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_3 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_4 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_5 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_6 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_7 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_8 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_9 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_10 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_pdv_ipn_11 }}
                      </div>
                    </div>
                    <div style="display: flex; flex: 0 0 102px;" class="code-wrapper">
                      <div class="code-box small"></div>
                      <div class="code-box small"></div>
                      <div class="code-box small"></div>
                      <div class="code-box small"></div>
                    </div>
                    <div style="display: flex; flex: 0 0 216px;" class="code-wrapper">
                      <div class="code-box small">
                        {{ document_data.organization_code_0 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_1 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_2 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_3 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_4 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_5 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_6 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_7 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_8 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.organization_code_9 }}
                      </div>
                    </div>
                    <div style="display: flex; flex: 0 0 32px;" class="code-wrapper">
                      <div class="code-box small">
                        {{ document_data.organization_code_type }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div style="flex: 0 0 262px; text-align: center">
                      (індивідуальний податковий номер)
                    </div>
                    <div style="flex: 0 0 84px; text-align: center">
                      (номер філії<sup><small>2</small></sup>)
                    </div>
                    <div style="flex: 0 0 217px; padding-left: 20px; text-align: center">
                      (податковий номер платника податку<sup><small>3</small></sup> або серія (за наявності) та номер паспорта<sup><small>4</small></sup>)
                    </div>
                    <div style="flex: 0 0 32px; padding-left: 16px; text-align: center">
                      (код<sup><small>5</small></sup>)
                    </div>
                  </div>
                </div>
                <div style="flex: 1"></div>
                <div style="flex: 0 0 48%" class="bordered-all pa-1">
                  <div class="font-weight-bold" style="font-size: 12px">
                    Отримувач (покупець)
                  </div>
                  <div style="height: 32px; width: 96%; padding: 2px 4px" class="bordered-all">
                    {{ document_data.contractor_name }}
                  </div>
                  <div style="text-align: center">
                    (найменування; прізвище, ім'я, по батькові (за наявності) - для фізичної особи - підприємця)
                  </div>
                  <div class="mt-2 d-flex">
                    <div style="display: flex; flex: 0 0 262px;" class="code-wrapper">
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_0 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_1 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_2 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_3 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_4 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_5 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_6 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_7 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_8 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_9 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_10 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_pdv_ipn_11 }}
                      </div>
                    </div>
                    <div style="display: flex; flex: 0 0 102px;" class="code-wrapper">
                      <div class="code-box small"></div>
                      <div class="code-box small"></div>
                      <div class="code-box small"></div>
                      <div class="code-box small"></div>
                    </div>
                    <div style="display: flex; flex: 0 0 216px;" class="code-wrapper">
                      <div class="code-box small">
                        {{ document_data.contractor_code_0 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_1 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_2 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_3 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_4 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_5 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_6 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_7 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_8 }}
                      </div>
                      <div class="code-box small">
                        {{ document_data.contractor_code_9 }}
                      </div>
                    </div>
                    <div style="display: flex; flex: 0 0 32px;" class="code-wrapper">
                      <div class="code-box small">
                        {{ document_data.contractor_code_type }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div style="flex: 0 0 262px; text-align: center">
                      (індивідуальний податковий номер)
                    </div>
                    <div style="flex: 0 0 84px; text-align: center">
                      (номер філії<sup><small>2</small></sup>)
                    </div>
                    <div style="flex: 0 0 217px; padding-left: 20px; text-align: center">
                      (податковий номер платника податку<sup><small>3</small></sup> або серія (за наявності) та номер паспорта<sup><small>3</small></sup>)
                    </div>
                    <div style="flex: 0 0 32px; padding-left: 16px; text-align: center">
                      (код<sup><small>5</small></sup>)
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-2" style="font-size: 11px; font-weight: bold; line-height: 14px;">
                <div class="pre-table-header bordered-all">
                  Розділ А
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    І
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Загальна сума коштів, що підлягають сплаті, з урахуванням податку на додану вартість
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_total | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    ІI
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Загальна сума податку на додану вартість, у тому числі:
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_total_tax | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    ІII
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    загальна сума податку на додану вартість за основною ставкою
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_20_tax | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    ІV
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    загальна сума податку на додану вартість за ставкою 7 %
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_7_tax | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    V
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    загальна сума податку на додану вартість за ставкою 14 %
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_14_tax | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    VI
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Усього обсяги постачання за основною ставкою (код ставки 20)
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_20_base | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    VII
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Усього обсяги постачання за ставкою 7 % (код ставки 7)
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_7_base | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    VIII
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Усього обсяги постачання за ставкою 14 % (код ставки 14)
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_14_base | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    IX
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Усього обсяги постачання при експорті товарів за ставкою 0 % (код ставки 901)
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_0_base | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    X
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Усього обсяги постачання на митній території України за ставкою 0 % (код ставки 902)
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_0_base | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    XI
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Усього обсяги операцій, звільнених від оподаткування (код ставки 903)
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">
                    {{ document_data.doc_0_base | formatNumber }}
                  </div>
                </div>
                <div class="d-flex pre-table-header bordered-all border-ex-top">
                  <div style="flex: 0 0 64px; border-right: 1px solid; text-align: center">
                    XII
                  </div>
                  <div style="flex: 1; border-right: 1px solid">
                    Дані щодо зворотної (заставної) тари
                  </div>
                  <div style="flex: 0 0 260px; text-align: right">

                  </div>
                </div>
                <div class="pre-table-header bordered-all border-ex-top border-ex-bottom">
                  Розділ Б
                </div>
              </div>
            </caption>
            <tr>
              <th rowspan="2" style="width: 40px; max-width: 40px;">
                № з/п
              </th>
              <th rowspan="2" style="width: 200px; max-width: 200px;">
                Опис (номенклатура) товарів/послуг продавця
              </th>
              <th colspan="3">
                Код
              </th>
              <th colspan="2">
                Одиниця виміру товару/послуги
              </th>
              <th rowspan="2" style="width: 70px; max-width: 70px;">
                Кількість (об'єм, обсяг)
              </th>
              <th rowspan="2" style="width: 80px; max-width: 80px;">
                Ціна постачання одиниці товару/послуги або
                максимальна роздрібна ціна товарів без
                урахування податку на додану вартість
              </th>
              <th rowspan="2" style="width: 42px; max-width: 42px;">
                Код ставки
              </th>
              <th rowspan="2" style="width: 42px; max-width: 42px;">
                Код пільги 7
              </th>
              <th rowspan="2" style="width: 80px; max-width: 80px;">
                Обсяги постачання (база оподаткування)
                без урахування податку на додану вартість
              </th>
              <th rowspan="2" style="width: 78px; max-width: 78px;">
                Сума податку на додану вартість
              </th>
              <th rowspan="2" style="width: 50px; max-width: 50px;">
                Код виду діяльності сільськогос-подарського товаро-виробника
              </th>
            </tr>
            <tr>
              <th style="width: 84px; max-width: 84px;">товару згідно з УКТ ЗЕД</th>
              <th style="width: 80px; max-width: 80px;">ознаки імпортованого товару 6</th>
              <th style="width: 60px; max-width: 60px;">послуги згідно з ДКПП</th>
              <th style="width: 80px; max-width: 110px;">умовне позначення (українське)</th>
              <th style="width: 40px; max-width: 40px;">код</th>
            </tr>
            <tr class="tr-body" v-for="item in document_data.table" :key="item.table_number">
              <td>{{ item.table_number }}</td>
              <td>{{ item.nomenclature }}</td>
              <td class="text-center">{{ item.code_uktz }}</td>
              <td></td>
              <td class="text-center">{{ item.code_dkpp }}</td>
              <td class="text-center">{{ item.unit_type }}</td>
              <td class="text-center">{{ item.unit_type_code }}</td>
              <td class="text-right">{{ item.count | formatNumber('0.0000') }}</td>
              <td class="text-right">{{ item.price }}</td>
              <td class="text-right">{{ item.percent }}</td>
              <td></td>
              <td class="text-right">{{ item.sum }}</td>
              <td class="text-right">{{ item.tax | formatNumber('0.0000') }}</td>
              <td></td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" style="text-align: center" class="pt-2">
                Суми податку на додану вартість, нараховані (сплачені) у зв'язку з постачанням товарів/послуг, зазначених
                у цій накладній, визначені правильно, відповідають сумі податкових зобов'язань продавця.
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="1" class="pt-2">

              </td>

              <td colspan="3" class="pt-2" style="vertical-align: bottom;">
                Посадова (уповноважена) особа / фізична особа
              </td>
              <td colspan="5" style="border-bottom: 1px solid; vertical-align: bottom;">
                {{ document_data.signed_person_name }}
              </td>
              <td colspan="5" class="pt-2">
                <div style="display: flex; justify-content: center" class="code-wrapper">
                  <div class="code-box">{{ document_data.signed_person_ipn_0 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_1 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_2 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_3 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_4 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_5 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_6 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_7 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_8 }}</div>
                  <div class="code-box">{{ document_data.signed_person_ipn_9 }}</div>
                </div>
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="1">

              </td>
              <td colspan="3" style="vertical-align: top;">
                (законний представник)
              </td>
              <td colspan="5" style="text-align: center">
                (Власне ім'я ПРІЗВИЩЕ)
              </td>
              <td colspan="5" style="text-align: center">
                (реєстраційний номер облікової картки платника податків
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" class="pt-1">
                1 У порядковому номері після символа "/" зазначається код 2 - у разі здійснення операцій з постачання
                власновироблених товарів, отриманих за результатами видів діяльності, визначених в пункті 161.3 статті 161
                Закону України "Про державну підтримку сільського господарства України", або код 5 - у разі складання
                податкової накладної оператором інвестору за багатосторонньою угодою про розподіл продукції, або код 6 - у
                разі складання податкової накладної для операцій з постачання товарів, базою оподаткування для яких
                встановлено максимальні роздрібні ціни.
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" class="pt-0">
                2 Зазначається у разі постачання/придбання товарів/послуг філією (структурним підрозділом), яка (який)
                фактично є від імені головного підприємства - платника податку стороною договору.
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" class="pt-0">
                3 Зазначається податковий номер платника податку відповідно до наказу Міністерства фінансів України від 09
                грудня 2011 року N 1588 "Про затвердження Порядку обліку платників податків і зборів", зареєстрованого у
                Міністерстві юстиції України 29 грудня 2011 року за N 1562/20300 (із змінами).
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" class="pt-0">
                4 Серію (за наявності) та номер паспорта зазначають у разі, якщо покупець або продавець - фізична особа,
                яка через свої релігійні переконання відмовляється від прийняття реєстраційного номера облікової картки
                платника податків та повідомила про це відповідний контролюючий орган і має відмітку у паспорті.
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" class="pt-0">
                5 Зазначається код ознаки джерела податкового номера відповідно до реєстру, якому належить податковий
                номер особи: 1 - Єдиний державний реєстр підприємств та організацій України (ЄДРПОУ); 2 - Державний реєстр
                фізичних осіб – платників податків (ДРФО); 3 - реєстраційний (обліковий) номер платника податків, який
                присвоюється контролюючими органами (для платників податків, які не включені до ЄДРПОУ); 4 - серія (за
                наявності) та номер паспорта (для фізичних осіб, які через свої релігійні переконання відмовляються від
                прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це
                відповідний контролюючий орган і мають відмітку у паспорті).
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" class="pt-0">
                6 У випадку постачання товару, ввезеного на митну територію України, у графі 3.2 проставляється позначка
                "Х".
              </td>
            </tr>
            <tr class="tfoot">
              <td colspan="14" class="pt-0">
                7 (у разі відсутності пільги у довідниках податкових пільг у графі 9 проставляється умовний код
                "99999999", а у цій графі зазначаються відповідні пункти (підпункти), статті, підрозділи, розділи
                Податкового кодексу України та/або міжнародного договору, якими передбачено звільнення від оподаткування)
              </td>
            </tr>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {convertNumberToLetterString} from "@/utils/accounting";
import documentPrintAPI from "@/utils/axios/accounting/documents/tax_bill_outcome"
import {formatDate} from "@/filters";
import {endOfMonth} from "@/utils/icons";

export default {
  name: "tax_bill_outcome_v1",
  props: {
    organization: {
      type: Object,
      default() {
        return {}
      }
    },
    print_data: {
      type: Object,
      default() {
        return {}
      }
    },
    document_id: {
      type: Number,
      default: null
    },
    global_settings: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      settings_watcher: null,
      document_setting: {
        additional_text: '',
        show_signature: true,
        duplicate_on_one_page: false,
        duplicate_on_differance_page: false
      },
      document_data: {date: '2022-12-31'}
    }
  },
  methods: {
    duplicateOnOnePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_differance_page = false
      }
    },
    duplicateOnDifferancePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_one_page = false
      }
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    formatDate,
    getResponsePerson(table, category, field = 'short_name') {
      if (table === 'organization') {
        return ((this.print_data?.response_people || []).find(i => i.category === category) || {})?.[field] || ''
      } else {
        return (this.document_data?.contractor_print_data || {})?.response_persons?.[category]?.[field] || ''
      }
    },
    getBalanceTitle() {
      const end_month = endOfMonth(this.document_data.month)
      const date = this.document_setting.balance_end ? end_month : this.document_data.month
      const balance = this.document_setting.balance_end
          ? this.document_data.balance_end : this.document_data.balance_start
      let balance_title = ''
      if (balance < 0) {
        balance_title = 'Переплата станом на'
      } else {
        balance_title = 'Заборгованість станом на'
      }

      return `${balance_title} ${formatDate(date, 'DD.MM.YYYY')}`
    },
    convertNumberToString(payload) {
      return convertNumberToLetterString(payload)
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler(payload) {
        if (payload) {
          documentPrintAPI.get_document_for_print(this.document_id)
              .then(response => response.data)
              .then(data => this.document_data = data)
        } else {
          this.document_data = {}
        }

      }
    }
  },
  created() {
    this.watch_settings()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.document-print-form {
  width: 100%;
  font-family: "Times New Roman", Calibri, serif;
  font-size: 10px;
  padding: 4px 12px;

  .pre-table-header {
    padding-left: 6px;
    padding-right: 6px;

    & > div {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .code-wrapper {
    .code-box {
      flex: 0 0 28px;
      font-size: 12px;
      border-top: 1px solid #111111;
      border-bottom: 1px solid #111111;
      border-left: 1px solid #111111;
      text-align: center;
      min-height: 20px;
      line-height: 18px;

      &:last-child {
        border-right: 1px solid #111111;
      }
    }

    .code-box.small {
      flex: 0 0 20px;
      font-size: 10px;
      line-height: 16px;
    }
  }


  .bordered-all {
    border: 1px solid #232323;
  }

  .border-ex-top {
    border-top: none !important;
  }

  .border-ex-right {
    border-right: none !important;
  }

  .border-ex-bottom {
    border-bottom: none !important;
  }

  .border-ex-left {
    border-left: none !important;
  }


  .document-table-form {
    width: 100%;
    border-collapse: collapse;

    caption {
      text-align: left;
    }

    tr {
      border: 1px solid black;

      th {
        border: 1px solid black;
        padding: 2px 2px;
        font-size: 10px;
        font-weight: bold;
        line-height: 13px;
      }
    }

    tr.tr-body {
      td {
        font-size: 12px;
        vertical-align: center;
      }
    }

    tr {
      td {
        border: 1px solid black;
        padding: 2px 2px;
        font-size: 10px;
        line-height: 12px;
      }
    }


    border: none !important;
    tr.tfoot {
      border: none;
      td {
        border: none;
      }
    }
  }
}
</style>